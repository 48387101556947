import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    super.connect()
    console.log("Do what ?");
  }

  remove(event) {
    event.preventDefault();
    let wrapper = event.target.closest(".nested-form-wrapper");

    if (wrapper && wrapper.dataset) {
      if (wrapper.dataset.newRecord === 'true') {
        wrapper.remove();
      } else {
        wrapper.style.display = 'none';
        wrapper.querySelector("input[name*='_destroy']").value = '1';
      }
    } else {
      console.error("Nested form wrapper not found");
    }
  }
}
