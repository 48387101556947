document.addEventListener("turbo:load", function() {
    var currentURL = window.location.href;

    if (currentURL.includes("/posts")) {
        const addListeners = () => {
            const checkboxes = document.querySelectorAll('.categories-input');
            checkboxes.forEach(function(checkbox) {
                checkbox.addEventListener('change', handleCheckboxChange);
            });
        };

        const checkbox = document.getElementById('martin-freimann');
        if (document.getElementById('martin-freimann')) {
            checkbox.addEventListener('change', function() {
                const hiddenInput = document.getElementById('hidden-freimann');
                if (checkbox.checked) {
                    hiddenInput.value = checkbox.value;
                } else {
                    hiddenInput.value = '';
                }
            });
        }

        const handleCheckboxChange = (event) => {
            event.preventDefault();

            const formData = new FormData(document.getElementById('filter_form'));
            const queryString = new URLSearchParams(formData).toString();
            const url = '/filter_posts?' + queryString + '&';

            fetch(url)
                .then(response => response.text())
                .then(data => {
                    const turboFrame = document.getElementById('posts');
                    turboFrame.innerHTML = data;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        };

        addListeners();
    }
});
