document.addEventListener('DOMContentLoaded', function () {
  var checkbox = document.getElementById('check-adress');
  var additionalForm = document.getElementById('additional_form');
  if (checkbox) {
    checkbox.addEventListener('change', function () {
      if (this.checked) {
        additionalForm.style.display = 'grid';
      } else {
        additionalForm.style.display = 'none';
      }
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  var checkboxSeminar = document.getElementById('check-adress-seminar');
  var additionalFormSeminar = document.getElementById('additional_form-seminar');
  if (checkboxSeminar) {
    checkboxSeminar.addEventListener('change', function () {
      if (this.checked) {
        additionalFormSeminar.style.display = 'grid';
      } else {
        additionalFormSeminar.style.display = 'none';
      }
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  var deleteButtons = document.querySelectorAll('.delete-button');

  function getLanguageCodeFromUrl() {
    var url = window.location.href;
    if (url.includes('en')) {
      return 'en';
    } else if (url.includes('de')) {
      return 'de';
    } else if (url.includes('fr')) {
      return 'fr';
    } else if (url.includes('it')) {
      return 'it';
    } else {
      return 'en';
    }
  }

  function getConfirmationMessage(lang) {
    switch (lang) {
      case 'de':
        return "Möchten Sie diesen Artikel wirklich löschen?";
      case 'fr':
        return "Êtes-vous sûr de vouloir supprimer cet article?";
      case 'it':
        return "Sei sicuro di voler eliminare questo articolo?";
      case 'en':
      default:
        return "Are you sure you want to delete this item?";
    }
  }

  var languageCode = getLanguageCodeFromUrl();

  deleteButtons.forEach(function (button) {
    button.addEventListener('click', function (event) {
      var confirmationMessage = getConfirmationMessage(languageCode);
      if (!confirm(confirmationMessage)) {
        event.preventDefault();
      }
    });
  });
});
