document.addEventListener("turbo:load", function () {
  var categoriesTitle = document.getElementById("categories-title");
  var categoriesContent = document.getElementById("categories-content");
  if (categoriesTitle) {
    categoriesTitle.addEventListener("click", function () {
      if (categoriesContent.style.display === "none" || categoriesContent.style.display === "") {
        categoriesContent.style.display = "block";
      } else {
        categoriesContent.style.display = "none";
      }
    });
  }
});

