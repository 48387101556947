document.addEventListener('turbo:load', function () {
  const filterItems = document.querySelectorAll('.filter-item');
  const allSeminars = document.querySelector('.all-seminars');
  const resilienzSeminars = document.querySelector('.resilienz-seminars');
  const leaderSeminars = document.querySelector('.leader-seminars');
  const personlichkeitSeminars = document.querySelector('.personlichkeit-seminars');
  const lernenSeminars = document.querySelector('.lernen-seminars');

  [resilienzSeminars, leaderSeminars, personlichkeitSeminars, lernenSeminars].forEach(sem => {
    if (sem) sem.classList.add('hidden');
  });

  filterItems.forEach(item => {
    item.addEventListener('click', function () {
      filterItems.forEach(i => i.classList.remove('active'));
      this.classList.add('active');

      const filter = this.getAttribute('data-filter');

      [allSeminars, resilienzSeminars, leaderSeminars, personlichkeitSeminars, lernenSeminars].forEach(sem => {
        if (sem) sem.classList.add('hidden');
      });

      switch (filter) {
        case 'resilienz':
          if (resilienzSeminars) resilienzSeminars.classList.remove('hidden');
          break;
        case 'leader':
          if (leaderSeminars) leaderSeminars.classList.remove('hidden');
          break;
        case 'personlichkeit':
          if (personlichkeitSeminars) personlichkeitSeminars.classList.remove('hidden');
          break;
        case 'lernen':
          if (lernenSeminars) lernenSeminars.classList.remove('hidden');
          break;
        default:
          if (allSeminars) allSeminars.classList.remove('hidden');
      }
    });
  });
});
