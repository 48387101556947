document.addEventListener('turbo:load', function () {
    var currentURL = window.location.href;

    if ((currentURL.includes("/admin") && !currentURL.includes("/admin/seminars")) || currentURL.includes("trainer")) {
        const uploaderBoxes = document.querySelectorAll('.file-drop-area');

        uploaderBoxes.forEach(function(uploaderBox) {
            const fileInput = uploaderBox.querySelector('input[type="file"]');
            const imagePreview = uploaderBox.querySelector('.imagePreview');

            fileInput.addEventListener('change', function () {
                const file = this.files[0];

                if (file) {
                    const reader = new FileReader();

                    reader.addEventListener('load', function () {
                        imagePreview.innerHTML = ''; // Clear previous preview
                        const imgElement = document.createElement('img');
                        imgElement.src = reader.result;
                        imgElement.title = 'image';
                        imgElement.alt = 'image';
                        imagePreview.appendChild(imgElement);
                    });

                    reader.readAsDataURL(file);
                }
            });
        });
    }
});
