const searchInput = document.querySelector('.search');
const clearButton = document.querySelector('.button-clear');
if (searchInput) {
    searchInput.addEventListener('focus', function () {
        clearButton.style.display = 'block';
        searchInput.style.padding = '12px 50px 12px 50px';
    });

    clearButton.addEventListener('click', function () {
        searchInput.value = '';
        clearButton.style.display = 'none';
        searchInput.style.padding = '12px 50px 12px 15px';
    });

    searchInput.addEventListener('blur', function () {
        blurTimeout = setTimeout(function () {
            clearButton.style.display = 'none'; // Сховати кнопку після виходу з фокусу
            searchInput.style.padding = '12px 50px 12px 15px';
        }, 100);
    });
}


document.addEventListener('turbo:load', function () {
    let lastScrollTop = 0;
    const header = document.getElementById('header');
    if (document.getElementById('header')) {
        window.addEventListener('scroll', () => {
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (currentScrollTop > lastScrollTop) {
                header.classList.remove('up');
                header.classList.add('down');
                header.classList.remove('open');
            } else {
                header.classList.remove('down');
                header.classList.add('up');
            }

            if (currentScrollTop < 50) {
                header.classList.add('up');
            }

            lastScrollTop = currentScrollTop;
        });
    }
});



const openMenu = document.querySelector('.open-menu-button');
if (openMenu) {
    openMenu.addEventListener('click', function () {
        header.classList.toggle('open');
    })
}





document.addEventListener('turbo:load', function () {
    const openLangMenuBtn = document.querySelector('.current-box');
    const closeLangMenuBtn = document.querySelector('.current-box-inner');
    const langMenu = document.querySelector('.lang-menu');

    if (openLangMenuBtn) {
        openLangMenuBtn.addEventListener('click', function () {
            langMenu.classList.add('open');
        });
        closeLangMenuBtn.addEventListener('click', function () {
            langMenu.classList.remove('open');
        });
    }
});


document.addEventListener("DOMContentLoaded", function () {
    var flashElements = document.querySelectorAll('.flash');

    flashElements.forEach(function (flashElement) {
        setTimeout(function () {
            flashElement.style.display = "none"
        }, 5000);
    });
});


//======================= pop-up ================================

document.addEventListener('turbo:load', function () {
    var popupOverlay = document.querySelector('.main-popup');
    var closeBtn = document.querySelector('.close-main-popup');
    var openPopupBtn = document.querySelector('.button.open-popup');
    if (document.querySelector('.main-popup')) {
        function openPopup() {
            popupOverlay.style.visibility = 'visible';
        }
        function closePopup() {
            popupOverlay.style.visibility = 'hidden';
        }

        closeBtn.addEventListener('click', closePopup);

        popupOverlay.addEventListener('click', function (event) {
            if (event.target === popupOverlay) {
                closePopup();
            }
        });

        openPopupBtn.addEventListener('click', openPopup);
    }
});

//======================= pop-up online format shop/show================================

document.addEventListener('turbo:load', function () {
    function displayOnlinePopup() {
        const onlinePopup = document.querySelector('.online-format-popup');
        if (onlinePopup) {
            onlinePopup.style.visibility = 'visible';
        }
    }

    function closePopup(onlinePopup) {
        onlinePopup.style.visibility = 'hidden';
    }

    function handleLabelClick(event) {
        if (window.location.href.includes("products")) {
            const forValue = event.target.getAttribute('for');
            if (forValue === "Online") {
                displayOnlinePopup();
            }
        }
    }

    const label = document.querySelector('label[for="Online"].language-label');
    if (label) {
        label.addEventListener('click', handleLabelClick);
    }

    const onlinePopup = document.querySelector('.online-format-popup');
    const closeOnlineBtn = document.querySelector('.close-online-format-popup');
    if (onlinePopup && closeOnlineBtn) {
        closeOnlineBtn.addEventListener('click', () => closePopup(onlinePopup));

        onlinePopup.addEventListener('click', function (event) {
            if (event.target === onlinePopup) {
                closePopup(onlinePopup);
            }
        });
    }
});

