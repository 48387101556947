import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';

var swiper = new Swiper(".partners-wrapper", {
    modules: [Autoplay],
    loop: true,
    slidesPerGroup: 1,
    slidesPerView: "auto",
    spaceBetween: 30,
    speed: 3000,
    autoplay: {
        delay: 1,
        enabled: true,
        disableOnInteraction: false,
    }
});

var swiper = new Swiper(".seminare-slider", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    watchSlidesProgress: true,
    loop: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        650: {
            slidesPerView: 2
        },
        950: {
            slidesPerView: 3
        },
        1250: {
          slidesPerView: 4
        }
    }
});

var swiper = new Swiper(".bewertungen-slider", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 15,
    loop: true,
    watchSlidesProgress: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        715: {
            slidesPerView: 2
        },
        1100: {
          slidesPerView: 3
        }
    }
});

var swiper = new Swiper(".books-slider", {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 20,
    watchSlidesProgress: true,
    loop: true,
    speed: 1000,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    autoplay: {
        delay: 1500,
        enabled: true,
        disableOnInteraction: false,
    },
    breakpoints: {
        650: {
            slidesPerView: 2
        },
        950: {
            slidesPerView: 3
        },
        1250: {
            slidesPerView: 4
        }
    }
});
