import "./packs/header";
import "./packs/sliders";
import "./packs/fade";
import "./packs/select";
import "./packs/posts"
import "./packs/admin"
import "./packs/shop"
import "./packs/scroll"
import "./packs/seminar_tabs"

